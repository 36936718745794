// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-04-templates-blog-post-index-tsx": () => import("/opt/build/repo/src/components/04_templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-components-04-templates-blog-post-index-tsx" */),
  "component---src-components-04-templates-tags-index-tsx": () => import("/opt/build/repo/src/components/04_templates/Tags/index.tsx" /* webpackChunkName: "component---src-components-04-templates-tags-index-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

